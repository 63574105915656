import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import MypageView from '../views/setting/MypageView.vue'
import MemberView from '../views/setting/MemberView.vue'
import RoleView from '../views/setting/RoleView.vue'
import MenuView from '../views/setting/MenuView.vue'
import ApiTestView from '../views/ApiTestView.vue'
import QuestionView from '../views/QuestionView.vue'
import TreeviewView from '../views/TreeviewView.vue'
import StatsAccessView from '../views/s3game/stats/StatsAccessView.vue'
import StatsPaymentView from '../views/s3game/stats/StatsPaymentView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: AboutView
  },
  // setting
  {
    path: '/setting/mypage',
    name: 'mypage',
    component: MypageView
  },
  {
    path: '/setting/member',
    name: 'member',
    component: MemberView
  },
  {
    path: '/setting/role',
    name: 'role',
    component: RoleView
  },
  {
    path: '/setting/menu',
    name: 'menu',
    component: MenuView
  },
  {
    path: '/lab/apitest',
    name: 'apitest',
    component: ApiTestView
  },
  {
    path: '/s3game/stats/access',
    name: 'statsAccess',
    component: StatsAccessView
  },
  {
    path: '/s3game/stats/payment',
    name: 'statsPayment',
    component: StatsPaymentView
  },
  {
    path: '/question',
    name: 'question',
    component: QuestionView
  },
  {
    path: '/gachihae',
    name: 'gachihae',
    component: TreeviewView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
