<template>
  <v-app>
    
    <common-error v-if="getApiError"></common-error>
    <base-frame v-else-if="fetchedToken" @logout="logout"></base-frame>
    <login-page v-else></login-page>

    <!-- 로딩 화면 -->
    <v-overlay :value="getOverlayState">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="getGlobalDialog"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
        >From the bottom</v-btn>
      </template> -->
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >{{ getGlobalDialogTitle }}</v-toolbar>
        <v-card-text>
          <div class="pa-12">{{ getGlobalDialogMessage }}</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="$store.dispatch('SET_GLOBAL_DIALOG', {onff:false})"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import CommonError from './components/CommonError.vue';
import LoginPage from './components/LoginPage.vue';
import BaseFrame from './components/BaseFrame.vue';

export default {
  name: 'App',

  created() {
    // console.log(process.env)
  },

  components: {
    CommonError,
    LoginPage,
    BaseFrame,
  },


  computed: {
    ...mapGetters([
      'fetchedToken',
      'getOverlayState',
      'getApiError',
      'getGlobalDialog',
      'getGlobalDialogTitle',
      'getGlobalDialogMessage',
    ])
  },

  data() {
    return {
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('INIT_TOKEN');
      this.$store.dispatch('INIT_USER_MENU_LIST');
      this.$router.push({path:'/'});
    }
  },
};
</script>
