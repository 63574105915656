<template>
  <div>
    <v-container>
      <v-card
        elevation="2"
        class="mx-auto"
        outlined
        width="95%"
      >
      <v-card-title>
        My Page
      </v-card-title>
  
      <v-card-text class="pb-0">
        <v-row>
          <v-col
            cols="12"
            md="4">
            <v-img
              contain
              max-height="100"
              aspect-ratio="1"
              :src="profileImage"></v-img>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="userInfo.uid"
              label="아이디"
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn block @click="changePassword">
              패스워드변경
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="userInfo.username"
              label="이름"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="userInfo.email"
              label="E-Mail"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="userInfo.phone"
              label="전화번호"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="userInfo.address"
              label="주소"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
          <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Pick an avatar"
                prepend-icon="mdi-camera"
                label="Avatar"
                v-model="userInfo.profileImage"
              ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-btn
          text
          color="teal accent-4"
          @click="modUserInfo"
        >
          SAVE
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-container>

    <v-dialog v-model="dialogChangePassword" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">패스워드 변경</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field
                  label="Current Password"
                  v-model="currentPassword"
                  :type="'password'"
                ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                  label="New Password"
                  v-model="newPassword1"
                  :type="'password'"
                ></v-text-field>
            </v-row>
            <v-row><v-text-field
                  label="Again Password"
                  v-model="newPassword2"
                  :type="'password'"
                ></v-text-field></v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeChangePassword">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="changePasswordConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="reveal">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
  export default {
    name: 'MypageView', //컴포넌트 이름
    components: {}, // 다른 컴포넌트 사용 시 컴포넌트를 import하고, 배열로 저장
    computed: {
      // uid() {
      //   return this.$store.state.userInfo.uid;
      // },
      // username() {
      //   return this.$store.state.userInfo.username;
      // },
      // email() {
      //   return this.$store.state.userInfo.email;
      // },
      // phone() {
      //   return this.$store.state.userInfo.phone;
      // },
      // address() {
      //   return this.$store.state.userInfo.address;
      // },
      // image() {
      //   return this.$store.state.userInfo.image;
      // },
      userInfo() {
        return this.$store.state.userInfo;
      },
      reveal() {
        return this.$store.state.apiProcessing;
      },
      profileImage() {
        return this.$store.state.userInfo.image;
      },
    },
    data() { // html과 자바스크립트 코드에서 사용할 데이터 변수 선언
      return {
        // reveal: false,
        dialogChangePassword: false,
        currentPassword: '',
        newPassword1: '',
        newPassword2: '',
      };
    },
    watch: {
      reveal (val) {
        // val && setTimeout(() => {
        //   this.reveal = false
        // }, 3000)
        console.log('reveal: ', val);
      },
    },
    methods: {
      modUserInfo() {
        let formData = new FormData();
        if ( this.userInfo.profileImage != undefined ) {
          formData.append("profileImage", this.userInfo.profileImage);
        }
        formData.append("uid", this.userInfo.uid);
        formData.append("password", this.userInfo.password);
        formData.append("username", this.userInfo.username);
        formData.append("email", this.userInfo.email);
        formData.append("phone", this.userInfo.phone);
        formData.append("address", this.userInfo.address);
        this.$store.dispatch('MOD_USER_INFO', {token:this.$store.state.token, formData:formData});
      },
      changePassword() {
        this.currentPassword = '';
        this.newPassword1 = '';
        this.newPassword2 = '';
        this.dialogChangePassword = true;
      },
      closeChangePassword() {
        this.dialogChangePassword = false;
      },
      changePasswordConfirm() {
        console.log('old password : ', this.currentPassword);
        console.log('new password #1 : ', this.newPassword1);
        console.log('new password #2: ', this.newPassword2);

        if ( this.newPassword1 != this.newPassword2 ) {
          alert('입력하신 비밀번호가 다릅니다');
          this.newPassword1 = '';
          this.newPassword2 = '';
          return;
        }
        this.$store.dispatch('CHANGE_PASSWD', {token:this.$store.state.token, uid: this.uid, oldPassword: this.currentPassword, newPassword: this.newPassword1});
        this.closeChangePassword();
        // this.reveal = this.$store.state.apiProcessing;
      },

    } // 컴포넌트 내에서 사용할 메소드 정의
  }
</script>

<style scoped>
</style>