<template>
  <v-app>
    <v-card class="mx-auto my-12" max-width="400">
      <v-container fluid>
        <v-img
          class="white--text align-end"
          height="200px"
          src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        >
          <v-card-title>GniFrix Manager</v-card-title>
        </v-img>

        <v-card-subtitle class="pb-0">
          아이디/패스워드 입력하세요
        </v-card-subtitle>

        <form>
          <v-text-field
            v-model="uid"
            label="ID"
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            :type="'password'"
            label="Password"
            required
            @keydown.enter="login"
          ></v-text-field>
  
          <v-tooltip bottom>
            <template v-slot:activator="{on,attrs}">
              <v-btn
                class="mr-4"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="login"
              >
                LOGIN
              </v-btn>
            </template>
            <span>로그인합니다</span>
          </v-tooltip>
          <v-btn @click="clear">
            clear
          </v-btn>
        </form>
      </v-container>
    </v-card>

    <v-container class="d-flex justify-center text-center mb-4">
      <v-alert
        :value="!alertState"
        color="pink"
        dark
        border="top"
        icon="mdi-alert"
        transition="scale-transition"
        width="400"
      >
        {{ alertMessage }}
      </v-alert>
    </v-container>
  
  </v-app>
</template>

<script>
// import axios from 'axios';
import { mapGetters } from 'vuex';

  export default {
    name: 'LoginPage', //컴포넌트 이름
    components: {}, // 다른 컴포넌트 사용 시 컴포넌트를 import하고, 배열로 저장
    data() { // html과 자바스크립트 코드에서 사용할 데이터 변수 선언
      return {
        uid: '',
        password: '',
        token: '',
      };
    },
    setup() {}, // 컴포지션 API
    beforeCreate() {}, // created() 전 실행
    created() {
      this.$store.dispatch('INIT_LOGIN_STATE');
    }, // 컴포넌트가 생성되면 실행
    beforeMount() {}, // mounted() 직전 실행
    mounted() {}, // template에 정의된 html 코드가 랜더링된 후 실행
    beforeUpdate() {},
    updated() {},
    beforeUnmount() {},
    unmounted() {},
    computed: {
      ...mapGetters([
        'fetchedToken',
      ]),
      alertState() {
        if ( this.$store.state.loginState != false ) return true;
        else return false;
      },
      alertMessage() {
        return this.$store.state.loginErrorMessage;
      },
    },

    methods: {
      // Login
      login() {
        this.$store.dispatch('INIT_TOKEN');
        this.$store.dispatch('INIT_USER_MENU_LIST');
        this.$store.dispatch('FETCH_TOKEN', {uid:this.uid, password:this.password} );
      },

      // Clear Form
      clear() {
        this.uid = '';
        this.password = '';
        this.$store.dispatch('INIT_LOGIN_STATE');
      },
    } // 컴포넌트 내에서 사용할 메소드 정의
  }
</script>

<style scoped>
</style>