<template>
  <div>
    <v-treeview class="transition-swing text-subtitle-2 mb-4"
      dense
      activatable
      hoverable
      open-on-click
      return-object
      @update:active="selected"
      :items="items">
      <template v-slot:prepend="{ item }">
        <v-icon>
          {{ item.icon }}
        </v-icon>
      </template>
    </v-treeview>

  </div>
</template>

<script>
  export default {
    name: 'SidemenuTreeview', //컴포넌트 이름
    computed: {
      items() {
        return this.$store.state.userMenuItemList;
      },
    },
    methods: {
      selected(node) {
        if ( node.length > 0 ) {
          this.$router.push(node[0].url);
        }
      },
    },
  }
</script>

<style scoped>
</style>