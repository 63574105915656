<template>
  <div>
    <v-card class="mx-auto" elevation="0">
      <!-- <v-card-title> -->
      <v-card-title>
        <span class="text-h5">온라인 문의</span>
      </v-card-title>
      <!-- </v-card-title> -->

      <v-divider></v-divider>

      <div class="table">
        <v-data-table
          :headers="headers"
          :items="questionList"
          :loading="loading"
          class="elevation-5"
          hide-default-footer
          @click:row="showRowInfo"
        >

          <template v-slot:[`item.filesCount`]="{ item }">
            <v-icon v-if="item.attachedFiles.length > 0"
              small
              class="mr-1"
            >
              mdi-paperclip
            </v-icon>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            <span> {{ splitedDate(item.createdAt) }} </span>
          </template>

        </v-data-table>
        
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="5"
            @input="handlePage"
          ></v-pagination>
        </div>
      </div>

    </v-card>

    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >{{ dialogItem.id }}</v-toolbar>
        <v-card-title color="blue darken-1">
          <v-text-field
            :value="dialogItem.questionTitle"
            solo
            readonly
          ></v-text-field>
          <!-- <v-text-field
            label="제목"
            readonly
            :value="dialogItem.questionTitle"
          ></v-text-field> -->
          <!-- <v-row>
            <v-col>
              <span class="text-h5">{{ dialogItem.questionTitle }}</span>
            </v-col>
          </v-row> -->
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="고객명"
                  readonly
                  :value="dialogItem.customerName"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="전화번호"
                  readonly
                  :value="dialogItem.customerPhone"
                ></v-text-field>
              </v-col>
              <v-col
              cols="12"
              sm="6"
              md="4"
              >
              <v-text-field
              label="작성일"
              readonly
              :value="splitedDate(dialogItem.createdAt)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                label="E-Mail"
                readonly
                :value="dialogItem.customerEmail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              filled
              auto-grow
              label="문의 내용"
              rows="2"
              row-height="20"
              readonly
              :value="dialogItem.customerContents"
            ></v-textarea>
          </v-row>
          <v-row>
            <v-col>
              <v-list flat dense>
                <v-subheader>첨부파일</v-subheader>
                <v-list-item-group
                  v-model="selectedItem"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in dialogItem.attachedFiles"
                    :key="i"
                    :href="item.url"
                    target="_blank"
                  >
                    <v-list-item-icon>
                      <!-- <v-icon v-text="mdi-paperclip"></v-icon> -->
                      <v-icon
                        small
                        class="mr-1"
                      >
                        mdi-paperclip
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.filename"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

            </v-col>
          </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
  export default {
    name: 'QuestionView', //컴포넌트 이름
    components: {}, // 다른 컴포넌트 사용 시 컴포넌트를 import하고, 배열로 저장
    data() { // html과 자바스크립트 코드에서 사용할 데이터 변수 선언
      return {
        dialog: false,
        page: 1,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: '제목', value: 'questionTitle' },
          { text: 'Attached', value: 'filesCount' },
          { text: '글쓴이', value: 'customerName' },
          { text: 'Created', value: 'createdAt' },
        ],
        dialogItem: {},
        selectedItem: 1,
        items: [
          { text: 'Real-Time', icon: 'mdi-clock' },
          { text: 'Audience', icon: 'mdi-account' },
          { text: 'Conversions', icon: 'mdi-flag' },
        ],
        clipIcon: 'mdi-'
      };
    },
    computed: {
      loading() {
        return this.$store.state.apiProcessing;
      },
      questionList() {
        return this.$store.state.apiResponse.list;
      },
      // page() {
      //   return this.$store.state.apiResponse.page;
      // },
      pageLength() {
        const tPageNum =  parseInt(this.$store.state.apiResponse.total / this.$store.state.apiResponse.limit);
        const bLastExist = this.$store.state.apiResponse.total % this.$store.state.apiResponse.limit;

        if ( bLastExist > 0 ) return tPageNum + 1;
        else return tPageNum;
      },
    },
    created() {
      this.$store.dispatch('FETCH_CUSTOMER_QUESTION_LIST', {token:this.$store.state.token, page:1, limit:5});
    }, // 컴포넌트가 생성되면 실행
    methods: {
      handlePage(item) {
        console.log('handlePage', item);
        this.$store.dispatch('FETCH_CUSTOMER_QUESTION_LIST', {token:this.$store.state.token, page:item, limit:5});
      },
      splitedDate(createdAt) {
        if (createdAt == undefined) return;
        var splitedDate = createdAt.split(/[T.+]/);
        return splitedDate[0]+' '+splitedDate[1];
      },
      showRowInfo(item) {
        this.dialogItem = Object.assign({}, item);
        this.dialog = true;
        console.log(item);
      }
    } // 컴포넌트 내에서 사용할 메소드 정의
  }
</script>

<style scoped>
.table {
  padding: 10px;
}
</style>