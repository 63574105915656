import { render, staticRenderFns } from "./CommonError.vue?vue&type=template&id=209c9baa&scoped=true&"
import script from "./CommonError.vue?vue&type=script&lang=js&"
export * from "./CommonError.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "209c9baa",
  null
  
)

export default component.exports