export default {
  SET_OVERLAY(state, onoff) {
    state.overlay = onoff;
  },
  INIT_TOKEN(state) {
    state.token = '';
    state.loginState = true;
    state.loginErrorMessage = '';
  },
  SET_TOKEN(state, signInfo) {
    state.token = signInfo.token;
    state.loginState = signInfo.state;
    state.loginErrorMessage = signInfo.message;
  },
  SET_SESSION_STATE(state, sessionState) {
    state.sessionState = sessionState;
  },
  SET_USERINFO(state, userInfo) {
    state.username = userInfo.username;
    state.usermail = userInfo.usermail;
    // state.userInfo.userimage = userInfo.userimage;
  },
  SET_USERINFO_TEST(state, userInfo) {
    state.userInfo = userInfo;
  },
  INIT_API_ERROR(state) {
    state.apiError = false;
    state.apiErrorMessage = '';
  },
  SET_API_ERROR(state, message) {
    state.apiError = true;
    state.apiErrorMessage = message;
  },
  INIT_USER_MENU_LIST(state) {
    state.userMenuList = [];
  },
  SET_USER_MENU_LIST(state, menuList) {
    state.userMenuList = menuList;
  },
  SET_USER_MENU_ITEM_LIST(state, menuList) {
    state.userMenuItemList = menuList;
  },

  SET_API_PROCESSING(state, result) {
    state.apiProcessing = result;
  },

  SET_USERS_LIST(state, members) {
    state.members = members;
  },

  SET_MENUS_LIST(state, menus) {
    state.menus = menus;
  },

  SET_ROLES_LIST(state, roles) {
    state.roles = roles;
  },

  SET_GLOBAL_DIALOG(state, param) {
    if ( param.onoff ) {
      state.gDialog = true;
      state.gDialogTitle = param.title;
      state.gDialogMessage = param.message;
    }
    else {
      state.gDialog = false;
      state.gDialogTitle = '';
      state.gDialogMessage = '';
    }
  },

  //SET_API_RESPONSE
  INIT_API_RESPONSE(state) {
    state.apiResponse = {};
  },
  SET_API_RESPONSE(state, response) {
    state.apiResponse = response;
  },
}