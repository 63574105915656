<template>
  <div>
    <v-card class="mx-auto" elevation="0">
      <!-- <v-card-title> -->
      <v-card-title>
        <span class="text-h5">Role</span>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          @click="newItem"
        >
        <v-icon
          left
          dark
        >
          mdi-account-multiple-plus
        </v-icon>
          NEW
        </v-btn>
      </v-card-title>
      <!-- </v-card-title> -->

      <v-divider></v-divider>

      <div class="table">
        <v-data-table
          :headers="headers"
          :items="roles"
          :loading="loading"
          class="elevation-5"
          @click:row="showRowInfo"
        >
      
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
        
      </div>

      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Dialog</span>
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="editedItem.role"
              label="Role"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1" text
              @click="closeDialog"
            >Cancel</v-btn>
            <v-btn
              color="blue darken-1" text
              @click="addRole"
            >OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'MenuView', //컴포넌트 이름
    components: {}, // 다른 컴포넌트 사용 시 컴포넌트를 import하고, 배열로 저장
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        isUpdate: false,
        editedItem: {},
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Role', value: 'role' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      }
    },
    computed: {
      loading() {
        return this.$store.state.apiProcessing;
      },
      roles() {
        return this.$store.state.roles;
      }
    },
    created() {
      this.$store.dispatch('FETCH_ROLES_LIST', this.$store.state.token);
    }, // 컴포넌트가 생성되면 실행
    methods: {
      showRowInfo(event, {item}) {
        console.log('table item click : ', item.id, item.role);
      },

      newItem() {
        this.editedItem = {};
        this.dialog = true;
      },

      editItem(item) {
        this.editedItem = Object.assign({}, item);
        this.isUpdate = true;
        this.dialog = true;
      },
      
      deleteItem(item) {
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },

      closeDialog() {
        this.dialog = false;
        this.isUpdate = false;
      },

      closeDelete() {
        this.dialogDelete = false;
      },

      addRole() {
        if ( this.isUpdate ) {
          console.log('ROLE: ', this.editedItem.role);
          this.$store.dispatch('UPDATE_ROLE', {token:this.$store.state.token, id: this.editedItem.id, role: this.editedItem.role});
          this.dialog = false;
        }
        else {
          console.log('ROLE: ', this.editedItem.role);
          this.$store.dispatch('ADD_ROLE', {token:this.$store.state.token, role: this.editedItem.role});
          this.dialog = false;
        }

        this.isUpdate = false;
      },

      deleteItemConfirm() {
        this.$store.dispatch('REMOVE_ROLE', {token:this.$store.state.token, role: this.editedItem.role});
        this.dialogDelete = false;
      },
    } // 컴포넌트 내에서 사용할 메소드 정의
  }
</script>

<style scoped>
.table {
  padding: 10px;
}
</style>