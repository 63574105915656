import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations.js';
import actions from './actions.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username:'',
    usermail:'',
    userInfo: {},
    overlay: false,
    loginState:false,
    loginErrorMessage: '',
    token: '',
    userMenuList: [],

    gDialog: false,
    gDialogTitle: '',
    gDialogMessage: '',

    // api result
    sessionState: false,
    apiProcessing: false,
    apiError: false,
    apiErrorMessage: '',

    // setting/member
    members: [],
    menus: [],
    roles: [],

    // common result
    apiResponse: {},
  },
  getters: {
    getOverlayState(state) {
      return state.overlay;
    },
    fetchedLoginState(state) {
      return state.loginState;
    },
    fetchedToken(state) {
      return state.token;
    },
    fetchedUserMenuList(state) {
      return state.userMenuList;
    },

    getApiProcessing(state) {
      return state.apiProcessing;
    },
    getSessionState(state) {
      return state.sessionState;
    },

    getApiError(state) {
      return state.apiError;
    },
    getApiErrorMessage(state) {
      return state.apiErrorMessage;
    },

    getGlobalDialog(state) {
      return state.gDialog;
    },
    getGlobalDialogTitle(state) {
      return state.gDialogTitle;
    },
    getGlobalDialogMessage(state) {
      return state.gDialogMessage;
    },

    getApiResponse(state) {
      return state.apiResponse;
    },
  },
  mutations,
  actions,
  modules: {
  }
})
