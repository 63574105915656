<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <!--
      -->
      <side-menu-frame :image="'http://wpmgr.gnifrix.com/resources/common/img/webportal.png'"></side-menu-frame>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>GniFrix Manager</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout" color="error">
        LOGOUT
        <v-icon
          right
          dark
        >
          mdi-logout
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <!--  -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import SideMenuFrame from './SideMenuFrame.vue';

  export default {
    name: 'BaseFrame',
    data: () => ({ drawer: null }),
    components: {
      SideMenuFrame,
    },
    methods: {
      logout() {
        this.$emit('logout');
      },
    }
  }
</script>