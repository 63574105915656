<template>
  <div>
    <v-card class="mx-auto" elevation="0">
      <v-card-title>
        <span class="text-h5">Member</span>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          @click="newItem"
        >
          NEW
          <v-icon
            right
            dark
          >
            mdi-human
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <div class="table">
        <v-data-table
          :headers="headers"
          :items="members"
          :loading="loading"
          class="elevation-5"
        >

          <template v-slot:[`item.image`]="{item}">
            <v-avatar size="36" color="indigo">
              <v-icon v-if="!item.image" dark>mdi-account-circle</v-icon>
              <img v-else :src="item.image" alt="item.username">
            </v-avatar>
          </template>
      
          <template v-slot:[`item.roles`]="{ item }">
            <v-select
                v-model="item.roles"
                :items="roleItems"
                item-text="name"
                item-value="value"
                attach
                chips
                multiple
                readonly
                dense
              ></v-select>
          </template>
      
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-1"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-1"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              small
              @click="changePassword(item)"
            >
              mdi-key
            </v-icon>
          </template>
        </v-data-table>
        
      </div>

      
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.uid"
                  label="UID"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field v-if="dialogType == 'NEW'"
                  v-model="editedItem.password"
                  label="Password #1"
                  :type="'password'"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field v-if="dialogType == 'NEW'"
                  v-model="password2"
                  label="Password #2"
                  :type="'password'"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.username"
                  label="Username"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.email"
                  label="E-Mail"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.phone"
                  label="Phone Number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dialogType == 'EDIT'">
              <v-select
                v-model="editedItem.roles"
                :items="roleItems"
                item-text="name"
                item-value="value"
                attach
                chips
                label="Roles"
                multiple
              ></v-select>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editedItem.address"
                label="Address"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Pick an avatar"
                prepend-icon="mdi-camera"
                label="Avatar"
                v-model="editedItem.profileImage"
              ></v-file-input>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveNewMember"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogChangePassword" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">패스워드 변경</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field
                  label="Current Password"
                  v-model="currentPassword"
                  :type="'password'"
                ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                  label="New Password"
                  v-model="newPassword1"
                  :type="'password'"
                ></v-text-field>
            </v-row>
            <v-row><v-text-field
                  label="Again Password"
                  v-model="newPassword2"
                  :type="'password'"
                ></v-text-field></v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeChangePassword">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="changePasswordConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MemberView', //컴포넌트 이름
    components: {}, // 다른 컴포넌트 사용 시 컴포넌트를 import하고, 배열로 저장
    data () {
      return {
        dialogType:'',
        dialog: false,
        dialogTitle: 'Member Dialog',
        dialogDelete: false,
        dialogChangePassword: false,
        editedItem: {},
        password2: '',
        currentPassword: '',
        newPassword1: '',
        newPassword2: '',
        headers: [
          {
            text: 'Avatar',
            align: 'start',
            sortable: false,
            value: 'image',
          },
          { text: 'UID', value: 'uid' },
          { text: 'Username', value: 'username' },
          { text: 'E-Mail', value: 'email' },
          { text: 'Phone Number', value: 'phone' },
          { text: 'Roles', value: 'roles', width: '30%' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        roleItems: [
          {name:'관리자', value:'ROLE_ADMIN'}, 
          {name:'사용자', value:'ROLE_USER'}, 
          {name:'손님', value:'ROLE_GUEST'}, 
          {name:'기본', value:'ROLE_DEFAULT'}
        ],
      }
    },
    computed: {
      loading() {
        return this.$store.state.apiProcessing;
      },
      members() {
        return this.$store.state.members;
      }
    },
    created() {
      this.$store.dispatch('FETCH_USERS_LIST', this.$store.state.token);
    }, // 컴포넌트가 생성되면 실행
    methods: {
      newItem() {
        this.dialogTitle = 'New Member';
        this.editedItem = {};
        this.password2 = '';
        this.dialog = true;
        this.dialogType = 'NEW';
      },
      editItem(item) {
        this.dialogTitle = 'Edit Member';
        this.editedItem = Object.assign({}, item);
        this.password2 = '';
        this.dialog = true;
        this.dialogType = 'EDIT';
      },
      deleteItem(item) {
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },
      changePassword(item) {
        this.editedItem = Object.assign({}, item);
        this.currentPassword = '';
        this.newPassword1 = '';
        this.newPassword2 = '';
        this.dialogChangePassword = true;
      },
      close() {
        this.dialog = false;
      },
      closeDelete() {
        this.dialogDelete = false;
      },
      deleteItemConfirm() {
        this.$store.dispatch('REM_MEMBER', {token:this.$store.state.token, uid: this.editedItem.uid});
        this.dialogDelete = false;
      },
      closeChangePassword() {
        this.dialogChangePassword = false;
      },
      saveNewMember() {
        let formData = new FormData();
        if ( this.editedItem.profileImage != undefined ) {
          formData.append("profileImage", this.editedItem.profileImage);
        }
        formData.append("uid", this.editedItem.uid);
        formData.append("password", this.editedItem.password);
        formData.append("username", this.editedItem.username);
        formData.append("email", this.editedItem.email);
        formData.append("phone", this.editedItem.phone);
        formData.append("address", this.editedItem.address);

        if ( this.dialogType === 'NEW' ) {
          /**
           * profileImage 포함 저장 시
           */
          this.$store.dispatch('ADD_MEMBER_TEST', {token:this.$store.state.token, formData:formData});
          // this.$store.dispatch('ADD_MEMBER', {token:this.$store.state.token, uid: this.editedItem.uid, password: this.editedItem.password, username: this.editedItem.username, email: this.editedItem.email, address: this.editedItem.address, phone: this.editedItem.phone});
        }
        else {
          formData.append("roles", this.editedItem.roles);
          this.$store.dispatch('MOD_MEMBER_TEST', {token:this.$store.state.token, formData:formData});
          // this.$store.dispatch('MOD_MEMBER', {token:this.$store.state.token, member: this.editedItem});
        }
        this.close();
      },
      changePasswordConfirm() {
        console.log('old password : ', this.currentPassword);
        console.log('new password #1 : ', this.newPassword1);
        console.log('new password #2: ', this.newPassword2);

        if ( this.newPassword1 != this.newPassword2 ) {
          alert('입력하신 비밀번호가 다릅니다');
          this.newPassword1 = '';
          this.newPassword2 = '';
          return;
        }
        this.$store.dispatch('CHANGE_PASSWD', {token:this.$store.state.token, uid: this.editedItem.uid, oldPassword: this.currentPassword, newPassword: this.newPassword1});
        this.closeChangePassword();
      }
    },
  }
</script>

<style scoped>
.table {
  padding: 10px;
}
</style>