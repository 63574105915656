import {
        login, getUserMenuList, getUserMenuItemList, getUsersList, getMenusList, getRolesList, 
        addRole, removeRole, updateRole, getApiTestList,
        getDailyAccessStats, getMonthlyAccessStats, getDailyPaymentsStats, getMonthlyPaymentsStats,
        addMember, addMemberTest, modMember, modMemberTest, changePassword, deleteMember, modUserInfo,
        addMenu, modMenu,
        getCustomerQuestionList,
      } from '../api/index.js';

export default {
  INIT_LOGIN_STATE(context) {
    context.commit('SET_TOKEN', {token:'', state:true, message:''});
  },
  SET_OVERLAY(context, onoff) {
    context.commit('SET_OVERLAY', onoff);
  },
  INIT_TOKEN(context) {
    context.commit('INIT_TOKEN');
  },
  LOGOUT_FOR_API_FAIL({commit}) {
    commit('SET_SESSION_STATE', false);
    commit('INIT_TOKEN');
    commit('INIT_USER_MENU_LIST');
  },
  FETCH_TOKEN(context, signInfo) {
    context.commit('SET_API_PROCESSING', true);
    login(signInfo)
      .then(({data}) => {
        if ( data.result == true ) {
          context.commit('SET_SESSION_STATE', true);
          context.commit('SET_TOKEN', {token:data.token, state:true, message:''});
          let userInfo = data.userInfo;
          context.commit('SET_USERINFO', {username:userInfo.username, usermail:userInfo.email, userimage:''});
          context.commit('SET_USERINFO_TEST', userInfo);
          context.commit('SET_USER_MENU_ITEM_LIST', data.menuList);
          context.commit('SET_API_PROCESSING', false);
        }
        else {
          context.commit('SET_TOKEN', {token:'', state:false, message:data.msg});
          context.commit('SET_API_PROCESSING', false);
        }
      })
      .catch(err => {
        console.log('FETCH_TOKEN::login error: ', err);
        context.commit('SET_TOKEN', {token:'', state:false, message:'로그인 에러 발생'});
        context.commit('SET_API_PROCESSING', false);
      });
  },

  SET_SESSION_STATE(context, sessionState) {
    context.commit('SET_SESSION_STATE', sessionState);

    if ( !sessionState ) {
      context.commit('INIT_TOKEN');
      context.commit('INIT_USER_MENU_LIST');
    }
  },

  INIT_USER_MENU_LIST({commit}) {
    commit('INIT_USER_MENU_LIST');
  },
  FETCH_USER_MENU_LIST({commit}, token) {
    commit('SET_API_PROCESSING', true);
    getUserMenuList(token)
      .then(({data}) => {
        if (data.code === 'SUCC_GET_USER_MENU_LIST') {
          commit('SET_USER_MENU_LIST', data.menuList);
          commit('SET_API_PROCESSING', false);
        }
      })
      .catch(err => {
        console.log('FETCH_USER_MENU_LIST::getUserMenuList error: ', err);
        commit('SET_API_PROCESSING', false);
      });
  },
  FETCH_USER_MENU_ITEM_LIST({commit}, token) {
    commit('SET_API_PROCESSING', true);
    getUserMenuItemList(token)
      .then(({data}) => {
        if (data.code === 'SUCC_GET_USER_MENU_ITEM_LIST') {
          commit('SET_USER_MENU_ITEM_LIST', data.menuList);
          commit('SET_API_PROCESSING', false);
        }
      })
      .catch(err => {
        console.log('FETCH_USER_MENU_ITEM_LIST::getUserMenuItemList error: ', err);
        commit('SET_API_PROCESSING', false);
      });
  },

  FETCH_USERS_LIST({commit,dispatch}, token) {
    commit('SET_API_PROCESSING', true);
    getUsersList(token)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_USERS_LIST', data.users);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
  
        // commit('SET_SESSION_STATE', false);
        // commit('INIT_TOKEN');
        // commit('INIT_USER_MENU_LIST');
        dispatch('LOGOUT_FOR_API_FAIL');
      }
    })
    .catch(err => {
      console.log('FETCH_USERS_LIST::getUsersList error: ', err);
      commit('SET_API_PROCESSING', false);

      // commit('SET_SESSION_STATE', false);
      // commit('INIT_TOKEN');
      // commit('INIT_USER_MENU_LIST');
      dispatch('LOGOUT_FOR_API_FAIL');
    });
    
  },
  ADD_MEMBER({commit, dispatch}, param) {
    commit('SET_API_PROCESSING', true);
    addMember(param.token, param.uid, param.password, param.username, param.email, param.address, param.phone)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_USERS_LIST', data.users);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('ADD_USER::addMember error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'Member 추가', message:'Member 추가 에러'});
    });
  },
  ADD_MEMBER_TEST({commit, dispatch}, param) {
    commit('SET_API_PROCESSING', true);
    addMemberTest(param.token, param.formData)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_USERS_LIST', data.users);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('ADD_MEMBER_TEST::addMemberTest error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'Member 추가', message:'Member 추가 에러'});
    });
  },
  MOD_MEMBER({commit, dispatch}, param) {
    console.log('actions::MOD_MEMBER start');
    commit('SET_API_PROCESSING', true);
    modMember(param.token, param.member)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_USERS_LIST', data.users);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('ADD_USER::moddMember error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'Member 수정', message:'Member 수정 에러'});
    });
  },
  MOD_MEMBER_TEST({commit, dispatch}, param) {
    commit('SET_API_PROCESSING', true);
    modMemberTest(param.token, param.formData)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_USERS_LIST', data.users);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('MOD_MEMBER_TEST::modMemberTest error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'Member 수정', message:'Member 수정 에러'});
    });
  },
  MOD_USER_INFO({commit, dispatch}, param) {
    commit('SET_API_PROCESSING', true);
    modUserInfo(param.token, param.formData)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_USERINFO_TEST', data.userInfo);
      commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('MOD_USER_INFO::modMemberTest error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'사용자정보 수정', message:'사용자정보 수정 에러'});
    });
  },
CHANGE_PASSWD({commit, dispatch}, param) {
    console.log('actions::CHANGE_PASSWD start');
    commit('SET_API_PROCESSING', true);
    changePassword(param.token, param.uid, param.oldPassword, param.newPassword)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('CHANGE_PASSWD::changePassword error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'패스워드 수정', message:'패스워드 수정 에러'});
    });
  },
  REM_MEMBER({commit, dispatch}, param) {
    commit('SET_API_PROCESSING', true);
    deleteMember(param.token, param.uid)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_USERS_LIST', data.users);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('REM_MEMBER::deleteMember error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'Member 삭제', message:'Member 삭제 에러'});
    });
  },

  ADD_MENU({commit, dispatch}, param) {
    commit('SET_API_PROCESSING', true);
    addMenu(param.token, param.name, param.url, param.ord, param.icon, param.depth)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_MENUS_LIST', data.menuList);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('ADD_MENU::addMenu error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'Menu 추가', message:'Menu 추가 에러'});
    });
  },
  MOD_MENU({commit, dispatch}, param) {
    console.log('actions::MOD_MENU start');
    commit('SET_API_PROCESSING', true);
    modMenu(param.token, param.menu)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_MENUS_LIST', data.menuList);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('MOD_MENU::modMenu error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'Menu 수정', message:'Menu 수정 에러'});
    });
  },



  FETCH_MENUS_LIST({commit, dispatch}, token) {
    commit('SET_API_PROCESSING', true);
    getMenusList(token)
    .then(({data}) => {
      if (data.code === 'SUCC_GET_ALL_MENU_LIST') {
        commit('SET_MENUS_LIST', data.menuList);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
  
        // commit('SET_SESSION_STATE', false);
        // commit('INIT_TOKEN');
        // commit('INIT_USER_MENU_LIST');
        dispatch('LOGOUT_FOR_API_FAIL');
      }
    })
    .catch(err => {
      console.log('FETCH_MENUS_LIST::getMenusList error: ', err);
      commit('SET_API_PROCESSING', false);

      // commit('SET_SESSION_STATE', false);
      // commit('INIT_TOKEN');
      // commit('INIT_USER_MENU_LIST');
      dispatch('LOGOUT_FOR_API_FAIL');
    });
  },
  
  FETCH_ROLES_LIST({commit, dispatch}, token) {
    commit('SET_API_PROCESSING', true);
    getRolesList(token)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_ROLES_LIST', data.roles);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        // commit('SET_SESSION_STATE', false);
        // commit('INIT_TOKEN');
        // commit('INIT_USER_MENU_LIST');

        // dispatch('LOGOUT_FOR_API_FAIL');
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('FETCH_ROLES_LIST::getRolesList error: ', err);
      commit('SET_API_PROCESSING', false);

      // commit('SET_SESSION_STATE', false);
      // commit('INIT_TOKEN');
      // commit('INIT_USER_MENU_LIST');

      // dispatch('LOGOUT_FOR_API_FAIL');
      dispatch('FETCH_API_ERROR', 'API 서버 응답 없음');
    });
  },
  ADD_ROLE({commit, dispatch}, param) {
    commit('SET_API_PROCESSING', true);
    addRole(param.token, param.role)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_ROLES_LIST', data.roles);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('ADD_ROLE::addRole error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'ROLE 추가', message:'ROLE 추가 에러'});
    });
  },
  REMOVE_ROLE({commit, dispatch}, param) {
    commit('SET_API_PROCESSING', true);
    removeRole(param.token, param.role)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_ROLES_LIST', data.roles);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('ADD_ROLE::removeRole error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'ROLE 삭제', message:'ROLE 삭제 에러'});
    });
  },
  UPDATE_ROLE({commit, dispatch}, param) {
    commit('SET_API_PROCESSING', true);
    updateRole(param.token, param.id, param.role)
    .then(({data}) => {
      if (data.result == true) {
        commit('SET_ROLES_LIST', data.roles);
        commit('SET_API_PROCESSING', false);
      }
      else {
        commit('SET_API_PROCESSING', false);
        
        dispatch('FETCH_API_ERROR', 'API 응답 오류');
      }
    })
    .catch(err => {
      console.log('ADD_ROLE::updateRole error: ', err);
      commit('SET_API_PROCESSING', false);

      dispatch('SET_GLOBAL_DIALOG', {onoff:true, title:'ROLE 수정', message:'ROLE 수정 에러'});
    });
  },



  INIT_API_ERROR({commit}) {
    commit('INIT_API_ERROR');
  },
  FETCH_API_ERROR({commit}, message) {
    commit('SET_API_ERROR', message);
  },

  SET_GLOBAL_DIALOG({commit}, param) {
    commit('SET_GLOBAL_DIALOG', param);
  },

  INIT_API_RESPONSE({commit}) {
    commit('INIT_API_RESPONSE');
  },

  FETCH_DAILY_ACCESS_STATS_LIST({commit, dispatch}, params) {
    commit('SET_API_PROCESSING', true);
    getDailyAccessStats(params.token, params.svcaId, params.appId, params.startDate, params.endDate)
    .then(({data}) => {
      commit('SET_API_RESPONSE', data);
      commit('SET_API_PROCESSING', false);
    })
    .catch(err => {
      console.log('FETCH_DAILY_ACCESS_STATS_LIST::getDailyAccessStats error: ', err);
      commit('SET_API_PROCESSING', false);
      dispatch('FETCH_API_ERROR', 'API 서버 응답 없음');
    });
  },

  FETCH_MONTHLY_ACCESS_STATS_LIST({commit, dispatch}, params) {
    commit('SET_API_PROCESSING', true);
    getMonthlyAccessStats(params.token, params.svcaId, params.appId, params.startDate, params.endDate)
    .then(({data}) => {
      commit('SET_API_RESPONSE', data);
      commit('SET_API_PROCESSING', false);
    })
    .catch(err => {
      console.log('FETCH_MONTHLY_ACCESS_STATS_LIST::getMonthlyAccessStats error: ', err);
      commit('SET_API_PROCESSING', false);
      dispatch('FETCH_API_ERROR', 'API 서버 응답 없음');
    });
  },

  FETCH_DAILY_PAYMENTS_STATS_LIST({commit, dispatch}, params) {
    commit('SET_API_PROCESSING', true);
    getDailyPaymentsStats(params.token, params.svcaId, params.appId, params.startDate, params.endDate)
    .then(({data}) => {
      commit('SET_API_RESPONSE', data);
      commit('SET_API_PROCESSING', false);
    })
    .catch(err => {
      console.log('FETCH_DAILY_PAYMENTS_STATS_LIST::getDailyPaymentsStats error: ', err);
      commit('SET_API_PROCESSING', false);
      dispatch('FETCH_API_ERROR', 'API 서버 응답 없음');
    });
  },

  FETCH_MONTHLY_PAYMENTS_STATS_LIST({commit, dispatch}, params) {
    commit('SET_API_PROCESSING', true);
    getMonthlyPaymentsStats(params.token, params.svcaId, params.appId, params.startDate, params.endDate)
    .then(({data}) => {
      commit('SET_API_RESPONSE', data);
      commit('SET_API_PROCESSING', false);
    })
    .catch(err => {
      console.log('FETCH_MONTHLY_PAYMENTS_STATS_LIST::getMonthlyPaymentsStats error: ', err);
      commit('SET_API_PROCESSING', false);
      dispatch('FETCH_API_ERROR', 'API 서버 응답 없음');
    });
  },

  FETCH_API_TEST_LIST({commit, dispatch}, params) {
    commit('SET_API_PROCESSING', true);
    getApiTestList(params.token, params.page, params.limit)
    .then(({data}) => {
      commit('SET_API_RESPONSE', data);
      commit('SET_API_PROCESSING', false);
      // if (data.result == true) {
      // }
      // else {
      //   commit('SET_API_PROCESSING', false);
      //   dispatch('FETCH_API_ERROR', 'API 응답 오류');
      // }
    })
    .catch(err => {
      console.log('FETCH_API_TEST_LIST::getApiTestList error: ', err);
      commit('SET_API_PROCESSING', false);
      dispatch('FETCH_API_ERROR', 'API 서버 응답 없음');
    });
  },

  FETCH_CUSTOMER_QUESTION_LIST({commit, dispatch}, params) {
    commit('SET_API_PROCESSING', true);
    getCustomerQuestionList(params.token, params.page, params.limit)
    .then(({data}) => {
      commit('SET_API_RESPONSE', data);
      commit('SET_API_PROCESSING', false);
    })
    .catch(err => {
      console.log('FETCH_CUSTOMER_QUESTION_LIST::getCustomerQuestionList error: ', err.response);
      commit('SET_API_PROCESSING', false);
      if ( err.response.data.message != null ) {
        dispatch('FETCH_API_ERROR', err.response.data.message);
      }
      else {
        dispatch('FETCH_API_ERROR', 'API 서버 응답 없음');
      }
    });
  },

}