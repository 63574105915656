<template>
  <div>
    <v-card class="mx-auto" elevation="0">
      <!-- <v-card-title> -->
      <v-card-title>
        <span class="text-h5">API Test</span>
      </v-card-title>
      <!-- </v-card-title> -->

      <v-divider></v-divider>

      <div class="table">
        <v-data-table
          :headers="headers"
          :items="apitestList"
          :loading="loading"
          :items-per-page="totalVisableCount"
          class="elevation-5"
          hide-default-footer
        >
        </v-data-table>
        
        <div class="text-center">
          <v-row>
            <v-col cols="2">
            </v-col>
            <v-col>
              <v-pagination
                v-model="page"
                :length="pageLength"
                :total-visible="totalVisableCount"
                @input="handlePage"
                circle
              ></v-pagination>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectCountPerPage"
                :items="pageItems"
                item-text="countPerPage"
                item-value="countValue"
                label="Count Per Page"
                @change="onSelectCountPerPage"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>

    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'ApiTestView', //컴포넌트 이름
    components: {}, // 다른 컴포넌트 사용 시 컴포넌트를 import하고, 배열로 저장
    data() { // html과 자바스크립트 코드에서 사용할 데이터 변수 선언
      return {
        page: 1,
        totalVisableCount: 5,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Address', value: 'address' },
          { text: 'Phone', value: 'phone' },
          { text: 'Email', value: 'email' },
          { text: 'Gender', value: 'gender' },
          { text: 'Created', value: 'createdAt' },
        ],
        selectCountPerPage: {countPerPage:'Five', countValue:5},
        pageItems: [
          {countPerPage:'Five', countValue:5},
          {countPerPage:'Ten', countValue:10},
          {countPerPage:'Fifteen', countValue:15},
        ],
      };
    },
    computed: {
      loading() {
        return this.$store.state.apiProcessing;
      },
      apitestList() {
        console.log('API TEST : ', this.$store.state.apiResponse);
        return this.$store.state.apiResponse.contents;
      },
      // page() {
      //   return this.$store.state.apiResponse.page;
      // },
      pageLength() {
        const tPageNum =  parseInt(this.$store.state.apiResponse.total / this.$store.state.apiResponse.limit);
        const bLastExist = this.$store.state.apiResponse.total % this.$store.state.apiResponse.limit;
        if ( isNaN(tPageNum) ) return 0;
        if ( bLastExist > 0 ) return tPageNum + 1;
        else return tPageNum;
      },
    },
    created() {
      this.$store.dispatch('FETCH_API_TEST_LIST', {token:this.$store.state.token, page:1, limit:this.totalVisableCount});
    }, // 컴포넌트가 생성되면 실행
    methods: {
      handlePage(item) {
        console.log('handlePage', item);
        console.log('totalVisableCount', this.totalVisableCount);
        this.$store.dispatch('FETCH_API_TEST_LIST', {token:this.$store.state.token, page:item, limit:this.totalVisableCount});
      },
      onSelectCountPerPage(item) {
        this.totalVisableCount = item;
        console.log(this.totalVisableCount);
        console.log(this.page);
        this.handlePage(this.page);
      },
    } // 컴포넌트 내에서 사용할 메소드 정의
  }
</script>

<style scoped>
.table {
  padding: 10px;
}
</style>