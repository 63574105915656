<template>
  <div>
    <v-card class="mx-auto" elevation="0">
      <v-card-title>
        <span class="text-h5">Menu</span>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          @click="newItem"
        >
          NEW
          <v-icon
            right
            dark
          >
            mdi-menu-open
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <div class="table">
        <v-data-table
          :headers="headers"
          :items="menus"
          :loading="loading"
          class="elevation-5"
        >

        <template v-slot:[`item.icon`]="{ item }">
            <v-icon>
              {{ item.icon }}
            </v-icon>
        </template>
      
        <template v-slot:[`item.forbidden`]="{ item }">
            <!-- <v-chip v-for="(it,index) in item.forbidden" :key="index">
              {{ it }}
            </v-chip> -->
            <v-select
                v-model="item.forbidden"
                :items="roleItems"
                item-text="name"
                item-value="value"
                attach
                chips
                multiple
                readonly
                dense
              ></v-select>
        </template>
      
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
        
      </div>
    </v-card>


    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.pid"
                  label="PID"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.depth"
                  label="Depth"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.name"
                  label="Name"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.ord"
                  label="Order"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.url"
                  label="URL"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.icon"
                  label="Icon"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dialogType == 'EDIT'">
              <v-col>

                <v-select
                  v-model="editedItem.forbidden"
                  :items="roleItems"
                  item-text="name"
                  item-value="value"
                  attach
                  chips
                  label="Roles"
                  multiple
                ></v-select>
              </v-col>
              <!-- <v-col>
                <v-autocomplete
                  :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']"
                  label="Interests"
                  multiple
                ></v-autocomplete>

              </v-col> -->
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MenuView', //컴포넌트 이름
    components: {}, // 다른 컴포넌트 사용 시 컴포넌트를 import하고, 배열로 저장
    data () {
      return {
        dialogType:'',
        dialog: false,
        dialogTitle: 'Menu Dialog',
        dialogDelete: false,
        editedItem: {},
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'PID', value: 'pid' },
          { text: 'Depth', value: 'depth' },
          { text: 'Name', value: 'name' },
          { text: 'Order', value: 'ord' },
          { text: 'URL', value: 'url' },
          { text: 'ICON', value: 'icon' },
          { text: 'Forbidden', value: 'forbidden', width: '30%' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        roleItems: [
          {name:'관리자', value:'ROLE_ADMIN'}, 
          {name:'사용자', value:'ROLE_USER'}, 
          {name:'손님', value:'ROLE_GUEST'}, 
          {name:'기본', value:'ROLE_DEFAULT'}
        ],
      }
    },
    computed: {
      loading() {
        return this.$store.state.apiProcessing;
      },
      menus() {
        return this.$store.state.menus;
      }
    },
    created() {
      this.$store.dispatch('FETCH_MENUS_LIST', this.$store.state.token);
    }, // 컴포넌트가 생성되면 실행
    methods: {
      newItem() {
        this.dialogTitle = 'New Menu';
        this.editedItem = {},
        this.dialog = true;
        this.dialogType = 'NEW';
      },
      editItem(item) {
        this.dialogTitle = 'Edit Menu';
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
        this.dialogType = 'EDIT';
      },
      deleteItem(item) {
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },
      close() {
        this.dialog = false;
      },
      closeDelete() {
        this.dialogDelete = false;
      },
      deleteItemConfirm() {
        this.dialogDelete = false;
      },
      save() {
        if ( this.dialogType === 'NEW' ) {
          this.$store.dispatch('ADD_MENU', {token:this.$store.state.token, name: this.editedItem.name, url: this.editedItem.url, ord: this.editedItem.ord, icon: this.editedItem.icon});
        }
        else {
          this.$store.dispatch('MOD_MENU', {token:this.$store.state.token, menu: this.editedItem});
        }
        this.close();
      }
    },
  }
</script>

<style scoped>
.table {
  padding: 10px;
}
</style>