<template>
  <div>
    <v-card class="mx-auto" elevation="0">
      <v-card-title>
        <span class="text-h5">접속 통계</span>

        <v-spacer></v-spacer>
        
        <v-row align="center">
          <v-col
            class="d-flex"
            cols="12"
            sm="2"
          >
            <v-radio-group
              v-model="calType"
              row
              @change="changeCalType"
            >
              <v-radio
                label="월별"
                value="month"
              ></v-radio>
              <v-radio
                label="일별"
                value="date"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="2"
          >
            <v-select
              :items="dmcItems"
              item-text="name"
              item-value="value"
              v-model="svcaId"
              label="DMC"
              dense
            ></v-select>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="2"
          >
            <v-select
              :items="appItems"
              item-text="name"
              item-value="value"
              v-model="appId"
              label="게임 구분"
              dense
            ></v-select>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="2"
          >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="시작날짜"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                :type="calType"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="2"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="종료날짜"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                :type="calType"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="2"
          >
            <v-btn
              depressed
              color="primary"
              @click="getStatsResult"
            >
              검색
            </v-btn>
          </v-col>
        </v-row>
        
      </v-card-title>

      <v-divider></v-divider>

      <div class="table">
        <v-data-table
          :headers="headers"
          :items="statsList"
          :loading="loading"
          class="elevation-5"
        >
        </v-data-table>
      </div>

    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'StatsAccessView', //컴포넌트 이름
    components: {}, // 다른 컴포넌트 사용 시 컴포넌트를 import하고, 배열로 저장
    data() { // html과 자바스크립트 코드에서 사용할 데이터 변수 선언
      return {
        headers: [
          {
            text: '날짜',
            align: 'start',
            sortable: false,
            value: 'dateIn',
          },
          { text: 'DMC', value: 'svcaId' },
          { text: '게임', value: 'appId' },
          { text: '시청횟수', value: 'pageViewCount' },
          { text: '이용가구', value: 'userViewCount' },
        ],
        dmcItems: [
          {name:'- 전체 -', value:''},
          {name:'KT WEB', value:'210'},
          {name:'BTV', value:'202'},
          {name:'STOA', value:'310'},
        ],
        appItems: [
          {name:'- 전체 -', value:''},
          {name:'지니신맞고', value:'6080'},
        ],
        svcaId:'',
        appId:'',
        startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        modal: false,
        menu: false,
        menu2: false,
        calType: 'date',
      };
    },
    methods: {
      changeCalType() {
        console.log(this.calType);
      },
      getStatsResult() {
        // console.log('calType :', this.calType);
        // console.log('DMC :', this.svcaId, ' , GAME :', this.appId);
        // console.log('startDate :', this.startDate, ' , endDate :', this.endDate);

        if ( this.calType === 'date' ) {
          // console.log('CALL API - FETCH_DAILY_ACCESS_STATS_LIST');
          this.$store.dispatch('FETCH_DAILY_ACCESS_STATS_LIST', {token:this.$store.state.token, svcaId:this.svcaId, appId:this.appId, startDate:this.startDate, endDate:this.endDate});
        }
        else {
          // console.log('CALL API - FETCH_MONTHLY_ACCESS_STATS_LIST');
          this.$store.dispatch('FETCH_MONTHLY_ACCESS_STATS_LIST', {token:this.$store.state.token, svcaId:this.svcaId, appId:this.appId, startDate:this.startDate, endDate:this.endDate});
        }
      },
    }, // 컴포넌트 내에서 사용할 메소드 정의
    computed: {
      loading() {
        return this.$store.state.apiProcessing;
      },
      statsList() {
        // console.log('API TEST : ', this.$store.state.apiResponse);
        return this.$store.state.apiResponse.resultList;
      },
    },
    created() {
      this.$store.dispatch('INIT_API_RESPONSE');
    },
  }
</script>

<style scoped>
.table {
  padding: 10px;
}
</style>