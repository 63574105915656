import axios from 'axios';

const config = {
  baseUrl: process.env.VUE_APP_URL,
}

function login(signInfo) {
  return axios.post(`${config.baseUrl}member/signIn`,
      {
        uid: signInfo.uid,
        password: signInfo.password
      },
      null
    );
}

function getUserMenuList(token) {
  return axios.get(`${config.baseUrl}menu/getUserMenuList`,
      {
        headers: {
          'X-AUTH-TOKEN': token,
        }
      }
    );
}

function getUserMenuItemList(token) {
  return axios.get(`${config.baseUrl}menu/getUserMenuItemList`,
      {
        headers: {
          'X-AUTH-TOKEN': token,
        }
      }
    );
}

function getUsersList(token) {
  return axios.get(`${config.baseUrl}member/getAllUsersInfo`,
      {
        headers: {
          'X-AUTH-TOKEN': token,
        }
      }
    );
}

function getMenusList(token) {
  return axios.get(`${config.baseUrl}menu/getAllMenuList`,
      {
        headers: {
          'X-AUTH-TOKEN': token,
        }
      }
    );
}

function getRolesList(token) {
  return axios.get(`${config.baseUrl}role/getAllRoles`,
      {
        headers: {
          'X-AUTH-TOKEN': token,
        }
      }
    );
}

function addRole(token, role) {
  return axios.post(`${config.baseUrl}role/addRole`,
    {
      role,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function removeRole(token, role) {
  return axios.post(`${config.baseUrl}role/removeRole`,
    {
      role,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function updateRole(token, id, role) {
  return axios.post(`${config.baseUrl}role/updateRole`,
    {
      id,
      role,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

// Member APIs
function addMember(token,uid,password,username,email,address,phone) {
  return axios.post(`${config.baseUrl}member/addMember`,
    {
      uid,
      password,
      username,
      email,
      address,
      phone,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function addMemberTest(token,param) {
  return axios.post(`${config.baseUrl}member/addMemberTest`,
    param,
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function modMember(token,member) {
  return axios.post(`${config.baseUrl}member/modMember`,
    {
      users: [member],
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function modMemberTest(token,param) {
  return axios.post(`${config.baseUrl}member/modMemberTest`,
    param,
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function modUserInfo(token,param) {
  return axios.post(`${config.baseUrl}member/modUser`,
    param,
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function modMembers(token,user) {
  return axios.post(`${config.baseUrl}member/modMember`,
    {
      user,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function deleteMember(token,uid) {
  return axios.post(`${config.baseUrl}member/deleteMember`,
    {
      uid,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

// Member APIs
function addMenu(token,name,url,ord,icon,depth) {
  return axios.post(`${config.baseUrl}menu/addMenu`,
    {
      name,
      url,
      ord,
      icon,
      depth,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function modMenu(token,menu) {
  return axios.post(`${config.baseUrl}menu/updateMenus`,
    {
      menus: [menu],
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function changePassword(token, uid, oldPassword, newPassword) {
  return axios.post(`${config.baseUrl}member/changePassword`,
    {
      uid,
      oldPassword,
      newPassword
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}


function getDailyAccessStats(token, svcaId, appId, startDate, endDate) {
  return axios.post(`${config.baseUrl}s3game/stats/dailyAccess`,
    {
      svcaId,
      appId,
      startDate,
      endDate,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function getMonthlyAccessStats(token, svcaId, appId, startDate, endDate) {
  return axios.post(`${config.baseUrl}s3game/stats/monthlyAccess`,
    {
      svcaId,
      appId,
      startDate,
      endDate,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function getDailyPaymentsStats(token, svcaId, appId, startDate, endDate) {
  return axios.post(`${config.baseUrl}s3game/stats/dailyPayments`,
    {
      svcaId,
      appId,
      startDate,
      endDate,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function getMonthlyPaymentsStats(token, svcaId, appId, startDate, endDate) {
  return axios.post(`${config.baseUrl}s3game/stats/monthlyPayments`,
    {
      svcaId,
      appId,
      startDate,
      endDate,
    },
    {
      headers: {
        'X-AUTH-TOKEN': token,
      }
    }
  );
}

function getApiTestList(token, page, limit) {
  return axios.post(`${config.baseUrl}apitest/getContents`,
    {
      page,
      limit,
    },
    {
        headers: {
          'X-AUTH-TOKEN': token,
        }
      }
    );
}

function getCustomerQuestionList(token, page, limit) {
  return axios.post(`${config.baseUrl}gfhome/customer/get-questions`,
    {
      page,
      limit,
    },
    {
        headers: {
          'X-AUTH-TOKEN': token,
        }
      }
    );
}


export {
  login,
  getUserMenuList,
  getUserMenuItemList,
  getUsersList,
  getMenusList,
  getRolesList,
  addMember,
  addMemberTest,
  modMember,
  modMemberTest,
  modMembers,
  modUserInfo,
  deleteMember,
  changePassword,
  addMenu,
  modMenu,
  addRole,
  removeRole,
  updateRole,
  getApiTestList,
  getDailyAccessStats,
  getMonthlyAccessStats,
  getDailyPaymentsStats,
  getMonthlyPaymentsStats,
  getCustomerQuestionList,
}