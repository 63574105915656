<template>
  <div>
    <h2>TreeView</h2>
    <v-treeview 
      dense
      activatable
      hoverable
      open-on-click
      return-object
      @update:active="selected"
      :items="items">
      <template v-slot:prepend="{ item }">
        <v-icon>
          {{ item.icon }}
        </v-icon>
      </template>
    </v-treeview>
  </div>
</template>

<script>
  export default {
    name: 'TreeviewView', //컴포넌트 이름
    computed: {
      items() {
        return this.$store.state.userMenuItemList;
      },
    },
    data() { // html과 자바스크립트 코드에서 사용할 데이터 변수 선언
      return {
      };
    },
    created() {
      this.$store.dispatch('FETCH_USER_MENU_ITEM_LIST', this.$store.state.token);
    }, // 컴포넌트가 생성되면 실행
    methods: {
      selected(node) {
        if ( node.length > 0 ) {
          console.log('selected node: ', node);
          this.$router.push(node[0].url);

        }
      },
    } // 컴포넌트 내에서 사용할 메소드 정의
  }
</script>

<style scoped>
</style>