<template>
  <v-app>
    <v-card class="mx-auto my-12">
      <v-card-title>API Error</v-card-title>
      <v-card-text>{{ $store.state.apiErrorMessage }}</v-card-text>
      <v-btn
      depressed
      color="error" @click="logout">
      LOGOUT 페이지로 이동
      </v-btn>
    </v-card>
  </v-app>
</template>

<script>
  export default {
    name: 'CommonError', //컴포넌트 이름
    components: {}, // 다른 컴포넌트 사용 시 컴포넌트를 import하고, 배열로 저장
    data() { // html과 자바스크립트 코드에서 사용할 데이터 변수 선언
      return {
      };
    },
    created() {
    }, // 컴포넌트가 생성되면 실행
    methods: {
      logout() {
        this.$store.dispatch('LOGOUT_FOR_API_FAIL');
        this.$store.dispatch('INIT_API_ERROR');
      },
    } // 컴포넌트 내에서 사용할 메소드 정의
  }
</script>

<style scoped>
</style>