<template>
  <div>

    <v-list dense shaped>
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="require('../assets/webportal.png')"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item to="/setting/mypage">
        <v-list-item-content>
          <v-list-item-title class="text-h7">
            {{ username }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ usermail }}</v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <!-- <tree-menu :itemList="menuList" class="menu-list"></tree-menu> -->
    <sidemenu-treeview></sidemenu-treeview>

  </div>
</template>

<script>
// import TreeMenu from '../components/TreeMenu/TreeMenu.vue';
import SidemenuTreeview from '../components/SidemenuTreeview.vue'

  export default {
    name: 'SideMenuFrame', //컴포넌트 이름
    components: {
      // TreeMenu,
      SidemenuTreeview,
    }, // 다른 컴포넌트 사용 시 컴포넌트를 import하고, 배열로 저장
    props: ['image'],
    data() { // html과 자바스크립트 코드에서 사용할 데이터 변수 선언
      return {
      };
    },
    computed: {
      // menuList() {
      //   return this.$store.state.userMenuList;
      // },
      username() {
        return this.$store.state.username;
      },
      usermail() {
        return this.$store.state.usermail;
      },
    },
    setup() {}, // 컴포지션 API
    created() {
      // this.$store.dispatch('FETCH_USER_MENU_LIST', this.$store.state.token);
    }, // 컴포넌트가 생성되면 실행
    methods: {
    },
  }
</script>

<style scoped>
.menu-list {
  margin: 0;
  padding: 0;
}
</style>